import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';


@Component({
  selector: 'app-galton-board',
  templateUrl: './galton-board.component.html',
  styleUrls: ['./galton-board.component.scss'],
  standalone:false
})
export class GaltonBoardComponent {

  taxesDropDown:any|undefined;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit(){
     if (isPlatformBrowser(this.platformId)) {  
      //safe now to use document (ssr)
      const bootstrap = await import("bootstrap");
      this.taxesDropDown = new bootstrap.Dropdown(document.getElementById("taxesDropDown") as HTMLElement);
     }
  }

  DropDownClick(){
    this.taxesDropDown?.toggle();
  }

}
