
<div class="subnav d-none d-sm-block">
    <div class="container">
    <a routerLink="../galton-board"><img src="https://services.ifa.com/art/images/2836_40x40_c_galton-board-models.webp" alt="Models"><br /> Models</a>
    <a routerLink="proficiency-test"><img src="https://services.ifa.com/art/images/3152_40x40_c_galton-board-proficiency-test.webp" alt="Proficiency Test"><br/> Proficiency Test</a>
    <a routerLink="learn-more"><img src="https://services.ifa.com/art/images/2835_40x40_c_galton-board-learn-more.webp" alt="Learn More"><br /> Learn More</a>
    <a routerLink="stem"><img src="https://services.ifa.com/art/images/2981_40x40_c_galton-board-stem.webp" alt="STEM"><br /> STEM<sup>&trade;</sup></a>
    <a routerLink="videos"><img src="https://services.ifa.com/art/images/2838_40x40_c_galton-board-videos.webp" alt="Videos"><br /> Videos</a>
    <a routerLink="gallery"><img src="https://services.ifa.com/art/images/2837_40x40_c_galton-board-photos.webp" alt="Photos"><br /> Photos</a>
    <a routerLink="articles"><img src="https://services.ifa.com/art/images/2832_40x40_c_galton-board-articles.webp" alt="Articles"><br /> Articles</a>
    <a routerLink="probabilityexamplesinlife"><img src="https://services.ifa.com/art/images/2833_40x40_c_galton-board-bell-curve.webp" alt="Bell Curves"><br /> Bell Curves</a>
    <a routerLink="contact-us"><img src="https://services.ifa.com/art/images/2834_40x40_c_galton-board-contact-us.webp" alt="Contact Us"><br/> Contact Us</a>
</div>
</div> 

<div class="mx-auto d-sm-none d-md-none d-lg-none width100">
    <button class="dropdown-toggle mx-auto d-block sub-nav-buttons" id="taxesDropDown" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" (click)="DropDownClick()">
      More on Galton Board
  </button>
      <ul class="dropdown-menu nav-justified font-d-blue border-radius-none leftnegative28 background-blue-trans-faux" aria-labelledby="taxesDropDown">

        <li><a class="font-d-blue dropdown-item" routerLink="../galton-board"><img class="topnavicon" src="https://services.ifa.com/art/images/2836_40x40_c_galton-board-models.webp" class="topnavicon" alt="Models"> Models</a></li>
        <li><a class="font-d-blue dropdown-item" routerLink="proficiency-test"><img class="topnavicon" src="https://services.ifa.com/art/images/3152_40x40_c_galton-board-proficiency-test.webp" alt="Proficiency Test"> Proficiency Test</a></li>
        <li><a class="font-d-blue dropdown-item" routerLink="learn-more"><img class="topnavicon" src="https://services.ifa.com/art/images/2835_40x40_c_galton-board-learn-more.webp" alt="Learn More"> Learn More</a></li>
        <li><a class="font-d-blue dropdown-item" routerLink="stem"><img class="topnavicon" src="https://services.ifa.com/art/images/2981_40x40_c_galton-board-stem.webp" alt="STEM"> STEM<sup>&trade;</sup></a></li>
        <li><a class="font-d-blue dropdown-item" routerLink="videos"><img class="topnavicon" src="https://services.ifa.com/art/images/2838_40x40_c_galton-board-videos.webp" alt="Videos"> Videos</a></li>
        <li><a class="font-d-blue dropdown-item" routerLink="gallery"><img class="topnavicon" src="https://services.ifa.com/art/images/2837_40x40_c_galton-board-photos.webp" alt="Photos"> Photos</a></li>
        <li><a class="font-d-blue dropdown-item" routerLink="articles"><img class="topnavicon" src="https://services.ifa.com/art/images/2832_40x40_c_galton-board-articles.webp" alt="Articles"> Articles</a></li>
        <li><a class="font-d-blue dropdown-item" routerLink="bell-curves"><img class="topnavicon" src="https://services.ifa.com/art/images/2833_40x40_c_galton-board-bell-curve.webp" alt="Bell Curves"> Bell Curves</a></li>
        <li><a class="font-d-blue dropdown-item" routerLink="contact-us"><img class="topnavicon" src="https://services.ifa.com/art/images/2834_40x40_c_galton-board-contact-us.webp" alt="Contact Us"> Contact Us</a></li>
      </ul>
  </div> 


            <router-outlet></router-outlet> 
