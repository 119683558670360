import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GaltonBoardComponent } from './galton-board.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ContactModule } from '../contact/contact.module'
import { GaltonBoardContactUsModule } from './contact-us/galton-board-contact-us.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';




@NgModule({
  declarations: [GaltonBoardComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    RouterModule.forChild([
      {path:'', component:GaltonBoardComponent,

      children:[  
        {path:'', loadChildren: () => import('./galton-board-home.module').then(m => m.GaltonBoardHomeModule)},
        {path:'articles', loadChildren: () => import('./articles/galton-board-articles.module').then(m => m.GaltonBoardArticlesModule)},
        {path:'probabilityexamplesinlife', loadChildren: () => import('./bell-curves/galton-board-bell-curves.module').then(m => m.GaltonBoardBellCurvesModule)},
        {path:'contact-us', loadChildren: () => import('./contact-us/galton-board-contact-us.module').then(m => m.GaltonBoardContactUsModule)},
        {path:'desktop', loadChildren: () => import('./desktop/desktop.module').then(m => m.DesktopModule)},
        {path:'gallery', loadChildren: () => import('./photos/galton-board-photos.module').then(m => m.GaltonBoardPhotosModule)},
        {path:'learn-more', loadChildren: () => import('./learn-more/galton-board-learn-more.module').then(m => m.GaltonBoardLearnMoreModule)},
        {path:'stem', loadChildren: () => import('./stem/stem.module').then(m => m.StemModule)},
        {path:'videos', loadChildren: () => import('./videos/galton-board-videos.module').then(m => m.GaltonBoardVideosModule)},
        {path:'proficiency-test', loadChildren: () => import('./proficiency-test/proficiency-test.module').then(m => m.ProficiencyTestModule)},

      ]
      },
    
    ])
  ],
  exports: [RouterModule]  
})
export class GaltonBoardModule { }
